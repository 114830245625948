<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="width: 100%;">
    <el-card class="box-card" style="text-align: left;">
      <div slot="header" class="clearfix">
        <span>管理员管理</span>
      </div>
      <div>

        <br/>

        <el-form :inline="true" label-width="100px">
          <el-form-item label="账号">
            <el-input size="small" v-model="username"></el-input>
          </el-form-item>
          <el-form-item style="padding-left:20px;">
            <el-button type="primary" size="small" style="margin-right:10px;" @click="getList">查询</el-button>
          </el-form-item>
        </el-form>

        <br/>

        <el-row>
          <router-link :to="{path: `/admin/admin_manage/new`}">
            <el-button type="primary" size="small">新增</el-button>
          </router-link>
        </el-row>

        <br/>

        <el-table :data="list"  stripe style="margin-top:10px;">
          <el-table-column type="index" label="序号" width="50" header-align="center" align="center"/>
          <el-table-column prop="username" label="账号" header-align="center" align="center"/>
<!--          <el-table-column prop="roleName" label="角色" header-align="center" align="center"/>-->
          <el-table-column prop="merchantName" label="商户" header-align="center" align="center"/>
          <el-table-column label="操作"  header-align="center" align="center">
            <template slot-scope="scope">
              <el-row>
                <el-button type="warning" size="mini" @click="editItem(scope.row.id)" style="margin-left:10px;">修改
                </el-button>
                <el-button type="danger" size="mini" @click="deleteItem(scope.row.id)" class="m-2" v-if="id!==scope.row.id">删除</el-button>
              </el-row>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination style="margin: 10px 0 0 -10px;" background :page-size="pageSize" layout="total,prev, pager, next"
                       :total="total" :current-page="currentPage" @current-change="handleCurrentChange"/>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "index.vue",
  data() {
    return {
      fullscreenLoading: false,
      list: [],
      currentPage: 1,
      pageSize: 20,
      id:localStorage.getItem("admin_id"),
      username: null,
      total: 0
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      let _this = this
      let params = {
        page: _this.currentPage-1,
        page_size: _this.pageSize,
        username: _this.username,
      }
      _this.fullscreenLoading = true
      adminHttp.post('/backend/admin/queryAdminList', params).then(result => {
        if (result && result.code === 200) {
          if(result.data!==null){
            _this.list = result.data.content;
            _this.total = result.data.totalElements;
          } else {
            _this.list = [];
            _this.total = 0;
          }
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    handleCurrentChange(page) {
      this.params.page = page-1
      this.$nextTick(() => {
        this.getList()
      })
    },
    deleteItem(id) {
      let _this = this
      _this.$confirm('是否永久删除该管理员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: id,
        }
        _this.fullscreenLoading = true
        adminHttp.post('/backend/admin/deleteAdmin', params).then(result => {
          if (result.code === 200) {
            _this.$message.success('删除成功!')
            _this.$nextTick(() => {
              this.getList();
            })
          } else {
            this.$message.error(result.message || "删除失败，请稍后再试")
          }
        }).catch(error=>{
          _this.$message.error('请求失败')
        }).finally(()=>{
          _this.fullscreenLoading = false
        })
      })
    },
    editItem(id) {
      this.$router.push({path: `/admin/admin_manage/edit/${id}`})
    }
  }
}
</script>

<style scoped>

</style>